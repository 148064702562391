<template>
  <div class="homePage"></div>
</template>

<script lang="ts">
</script>

<style lang="less" scoped>
.homePage {
  background: url('../login/imgs/login-main-bg.jpg') no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
}
</style>
